<template>
  <div class="box">
    <el-menu
        :unique-opened="true"
        :router="true"
        :default-active="activeIndex"
        class="el-menu-vertical-demo">
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-picture-outline"></i>
          <span>资源管理</span>
        </template>
        <el-menu-item-group>
          <template slot="title">图片资源</template>
          <el-menu-item index="1-1-1" :route="{name:'imageManage'}">图片</el-menu-item>
          <!--          <el-menu-item index="1-1-2" :route="{name:'searchImageManage'}">搜索显示图片</el-menu-item>-->
          <el-menu-item index="1-1-3" :route="{name:'imageCategory'}">图片分类</el-menu-item>
          <el-menu-item index="1-1-4" :route="{name:'tutorialResource'}">教程分类</el-menu-item>
          <el-menu-item index="1-1-5" :route="{name:'recycleManage'}">回收站</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <template slot="title">其他资源</template>
          <el-menu-item index="1-2-1" :route="{name:'fontResource'}">字体</el-menu-item>
          <el-menu-item index="1-2-2" :route="{name:'modelResource'}">模型</el-menu-item>
          <el-menu-item index="1-2-3" :route="{name:'audioPackManager'}">语音包</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span>用户数据</span>
        </template>
        <el-menu-item-group>
          <template slot="title">反馈</template>
          <el-menu-item index="2-1-1" :route="{name:'feedback'}">用户反馈</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <template slot="title">数据</template>
          <el-menu-item index="2-2-1" :route="{name:'userShare'}">用户上传</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span>社区管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="3-11" :route="{name:'postManager'}">帖子管理页面</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span>文件管理</span>
        </template>
        <el-menu-item-group>
          <template slot="title">阿里云oss文件相关处理</template>
          <el-menu-item index="4-1" :route="{name:'fileManager'}">文件删除</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>趣玩管理</span>
        </template>
        <el-menu-item-group>
          <template slot="title">趣玩网页管理</template>
          <el-menu-item index="5-1" :route="{name:'miniGameManager'}">网页状态监听</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>消息中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="6-1" :route="{name:'messageCenter'}">消息中心</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="7">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>ComfyAI趣味P图</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="7-1" :route="{name:'comfyStyleManager'}">图片样式管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="8">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>APP广告报表</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="8-1" :route="{name:'adReportManager'}">广告报表指标</el-menu-item>
          <el-menu-item index="8-2" :route="{name:'appStatistics'}">APP统计</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="9">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>会员管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="9-1" :route="{name:'vipReportManager'}">会员统计</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="10">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>系统</span>
        </template>
        <el-menu-item-group>
          <template slot="title">配置</template>
          <el-menu-item index="10-1" :route="{name:'appSetting'}">App设置</el-menu-item>
          <el-menu-item index="10-2" :route="{name:'searchMatchRules'}">搜索规则</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <template slot="title">异常</template>
          <el-menu-item index="10-21" :route="{name:'exceptionInfo'}">服务器异常</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: function () {
    return {
      activeIndex: ""
    }
  },
  mounted() {
    const that = this;
    this.$bus.$on("setMenuActiveIndex", function (index) {
      that.activeIndex = index
    })
  }
}
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
}

.box .el-menu /deep/ {
  min-height: 100%;
}
</style>
